import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "app-row",
  templateUrl: "./row.component.html",
  styleUrls: ["./row.component.scss"]
})
export class RowComponent implements OnInit {
  @Input() information;
  public addNewItem: string = "Add new item";

  constructor() {}

  ngOnInit() {}

  openNewTab(item) {
    window.open(item, "_blank");
  }
  setTooltip(element, item) {
    element.setAttribute("tooltip", item.description);
  }
  openModal(openModal){
    openModal.animatedModal();
  }
}
