import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-table-utils",
  templateUrl: "./table-utils.component.html",
  styleUrls: ["./table-utils.component.scss"],
})
export class TableUtilsComponent implements OnInit {
  public documentation = [
    {
      site: "Disciplina de Software",
      url: [
        {
          name: "DOCUMENTACION COMPONENTES",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=3114363",
          description: "Documentación de los compoentes desarrollados SOE",
        },
        {
          name: "LIBERACIÓN COMPONENTES",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=82788449",
          description:
            "Procedimiento de notificación de desarrollos liberados COMPONENTES COMUNES OSP",
        },        
        {
          name: "POLITICA DE RAMAS",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=86918842",
          description: "Estandar de la politica de ramas",
        },
        {
          name: "VERSIONADO",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=86918840",
          description: "Guia versionado",
        },
        {
          name: "NOMENCLATURA",
          value: "https://confluence.si.orange.es/display/DYAD/5.+Nomenclatura",
          description: "Guia de nomenclatura",
        },
        {
          name: "CABECERAS",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=12387204",
          description:
            "Que cabeceras enviamos en las peticiones y Transformacion de parametros",
        },
        {
          name: "FLUJOS",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=26595046",
          description: "Control de flujos y documentación",
        },        
        {
          name: "ESTANDAR ID´S",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=3123628",
          description: "Estandar identificadores SOE",
        },     
        {
          name: "Generación de mensajes error",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=17695727",
          description: "Como generamos los mensajes de error",
        },   
        {
          name: "TEALIUM",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=30639804",
          description: "¿Que es Tealium y como lo aplicamos?",
        },
        {
          name: "DOCUMENTACION REFINAMIENTOS",
          value: "https://confluence.si.orange.es/display/FCOM/Refinamientos",
          description: "Documentación refinamientos",
        },
        {
          name: "Recursos",
          value:
            "https://confluence.si.orange.es/display/FCOM/Recursos+de+Prueba",
          description: "Recursos y urls de acceso a las distintas SPAS",
        },
      ],
    },
    {
      site: "WC INFORMATION",
      url: [
        {
          name: "Herramientas de desarrollo",
          value:
            "https://confluence.si.orange.es/display/WEBCOMP/Herramientas+de+desarrollo",
          description: "Herramientas de desarrollo para WC",
        },
        {
          name: "Preparación de entorno de trabajo",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=183147724",
          description:
            "Preparación de entorno de trabajo",
        },
        {
          name: "Generación esqueleto de WC",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=183147715",
          description: "Como generar la estructura base de un WC",
        },
        {
          name: "Generación XPL",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=183147719",
          description: "Generar una XPL mediante la herramienta blueprint",
        },
        {
          name: "Integración web component en SPA AngularJS",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=183147721",
          description: "Como integrar un web component en una SPA AngularJS",
        },
        {
          name: "Integración web component en SPA AngularJS (Mas INFO)",
          value:
            " https://confluence.si.orange.es/pages/viewpage.action?pageId=116506783",
          description: "Como integrar un web component en una SPA AngularJS",
        },
        {
          name: "Integración web component en SPA Angular 5",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=116508173",
          description: "Como integrar un web component en una SPA Angular 5",
        },
        {
          name: "Integración web component en SPA Angular 8",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=116508175",
          description: "Como integrar un web component en una SPA Angular 8",
        },
        {
          name: "Integración web component en SPA Angular (Mas INFO)",
          value:
            " https://confluence.si.orange.es/pages/viewpage.action?pageId=183147726",
          description: "Como integrar un web component en una SPA Angular",
        },
        {
          name: "Ejemplos practicos Crear/integrar un WC desde 0",
          value: "https://confluence.si.orange.es/display/DESALL/3.2.0.2.1+Videos",
          description: "Ejemplos practicos en video para crear/integrar un WC desde 0",
        },
        {
          name: "Guia WC (Desarrollo y Despliegue)",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=188096133",
          description:
            "Cómo desarrollar y desplegar web components y experience layer",
        },
        {
          name: "Estructura base de un web component",
          value:
            "https://confluence.si.orange.es/display/WEBCOMP/Estructura+base+web+component",
          description: "Estructura base de un web component",
        },
        {
          name: "Metodología de trabajo con wrappers",
          value:
            "https://confluence.si.orange.es/pages/viewpage.action?pageId=177866687",
          description: "¿Como se trabaja con Wrappers?",
        },
        {
          name: "Documentacion Tecnica Blueprint ARQ",
          value:
            "https://confluence.si.orange.es/display/KBARCH/Documentacion+Tecnica+Blueprint+ARQ",
          description: "Documentacion Tecnica Blueprint Arquitectura",
        },
        {
          name: "Blueprints APIs & Microservicios",
          value: "http://blueprint-newsoe.cloud-test.cloud.si.orange.es/index.html",
          description: "Documentación Blueprints APIs & Microservicios",
        },
        {
          name: "Caché distribuida para micros basada en WC",
          value: "https://confluence.si.orange.es/pages/viewpage.action?pageId=113748300",
          description: "Caché distribuida para micros basada en WC",
        },
        {
          name: "Bundler ",
          value: "https://confluence.si.orange.es/display/WEBCOMP/Bundler#space-menu-link-content",
          description: "Bundler ",
        },
        {
          name: "Experience layer ",
          value: "https://confluence.si.orange.es/display/DESALL/6.1+Experience+layer",
          description: "Documentación sobre Experience layer",
        },
        {
          name: "Guia estilos WC - Orange",
          value: " https://www.figma.com/file/gVD2u5T8JdiYx7SKLYuqlMW1/guidelinesBase?node-id=1%3A68",
          description: "Guia estilos WC - Orange",
        },
        {
          name: "Guía de buenas practicas WC",
          value: "https://confluence.si.orange.es/display/DESALL/2.4.1+Angular+checklist",
          description: "Guía de buenas practicas CheckList",
        },
        {
          name: "Modelo de gobierno de componentes",
          value: "https://confluence.si.orange.es/display/DESALL/Modelo+de+gobierno+de+componentes",
          description: "Modelo de gobierno de componente",
        },
        {
          name: "Listado de WC Actuales",
          value: "https://confluence.si.orange.es/display/DESALL/Componentes",
          description: "Listado de WC Actuales",
        },
        {
          name: "Catalogo de Componentes",
          value: "https://confluence.si.orange.es/pages/viewpage.action?pageId=154189504",
          description: "Catalogo de Componentes",
        },
        {
          name: "Openapi Developer Portal",
          value: "https://confluence.si.orange.es/pages/viewpage.action?pageId=154189504",
          description: "https://devportal.apigee.shared.cloud.si.orange.es/api-products",
        },
      ],
    },
    {
      site: "APIs Microservicios  - PORTAL UNIFICADO - Confluence",
      url: [
        {
          name: "TMForum",
          value: "https://confluence.si.orange.es/display/PU/APIs+TMForum",
          description: "Microservicios TMForum BAU",
        },
        {
          name: "No TMForum",
          value: "",
          description: "Microservicios NO TMForum BAU",
        },
        {
          name: "APIS WC",
          value:
            "https://confluence.si.orange.es/display/KBARCH/Construir+un+API+de+Experience",
          description: "Microservicios para WC BAU/ALANI",
        },
      ],
    }, 
    {
      site: "MBF",
      url: [
        {
          name: "INT",
          value:
            "https://areaclientes.int.si.orange.es/?goto=https:%2F%2Fecare.uat.si.orange.es%2Fclientes%2F",
          description: "Abre la SPA ENDOR en el entorno de INT",
        },
        {
          name: "UAT",
          value:
            "https://areaclientes-uat.int.si.orange.es/?goto=https:%2F%2Fecare.uat.si.orange.es%2Fclientes%2F",
          description: "Abre la SPA ENDOR en el entorno de UAT",
        },
        {
          name: "PRO",
          value:
            "https://areaclientes.orange.es/index.html?internal_source=orange&internal_medium=header&internal_term=cabecera+enlace+mi+orange&internal_campaign=ecare",
          description: "Abre la SPA ENDOR en el entorno de PRODUCCION",
        },
        {
          name: "PREVIEW",
          value: "https://preview-mfo.si.orange.es",
          description: "Abre la SPA ENDOR en el entorno de Preview",
        },
      ],
    },
    {
      site: "FICHA",
      url: [
        {
          name: "INT",
          value: "https://fichadecliente.int.si.orange.es",
          description: "Abre la SPA FICHA en el entorno de INT",
        },
        {
          name: "UAT",
          value: "https://fichadecliente-uat.int.si.orange.es",
          description: "Abre la SPA FICHA en el entorno de UAT",
        },
        {
          name: "PRO",
          value: "https://fichadecliente.si.orange.es",
          description: "Abre la SPA FICHA en el entorno de PRO",
        },
      ],
    },
    {
      site: "AMENA",
      url: [
        {
          name: "INT",
          value: "https://ecare.int.si.amena.com/clientes/public/#/login",
          description: "Abre la SPA ENDORA en el entorno de INT",
        },
        {
          name: "UAT",
          value: "https://ecare.uat.si.amena.com/clientes/public/#/login",
          description: "Abre la SPA ENDORA en el entorno de UAT",
        },
        {
          name: "PRO",
          value: "https://www.amena.com/",
          description: "Abre la SPA ENDORA en el entorno de PRO",
        },
      ],
    },
    {
      site: "PAE",
      url: [
        {
          name: "INT",
          value: "https://ecare.int.si.orange.es/empresas/public/#/login",
          description: "Abre la SPA PAE en el entorno de INT",
        },
        {
          name: "UAT",
          value: "https://ecare.uat.si.orange.es/empresas/public/#/login",
          description: "Abre la SPA PAE en el entorno de UAT",
        },
        {
          name: "Preview",
          value: "https://preview-pae.si.orange.es/",
          description: "Abre la SPA PAE en el entorno de Preview",
        },
      ],
    },
    {
      site: "PANGEA",
      url: [
        {
          name: "INT",
          value: "https://pangea.int.si.orange.es/#/",
          description: "Abre la SPA PDV en el entorno de INT",
        },
        {
          name: "UAT",
          value: "https://pangea-uat.int.si.orange.es/#/",
          description: "Abre la SPA PDV en el entorno de UAT",
        },
      ],
    },
    {
      site: "AMON/JAZZTEL",
      url: [
        {
          name: "Preview",
          value: "https://preview-amon.jazztel.com/",
          description: "Abre la SPA JAZZTEL en el entorno de Preview",
        },
      ],
    },
    {
      site: "FIBY",
      url: [
        {
          name: "INT",
          value:
            "https://impulsivebuying.int.si.orange.es/?otv_servicenumber=918447013&canal=TVPACKPARTIDAZO&dispositivo=SmartTV&proxy=false",
          description: "Abre la SPA Fiby en el entorno de INT",
        },
        {
          name: "UAT",
          value:
            "https://impulsivebuying-uat.int.si.orange.es/?otv_servicenumber=918447013&canal=TVPACKPARTIDAZO&dispositivo=SmartTV&proxy=false",
          description: "Abre la SPA Fiby en el entorno de UAT",
        },
      ],
    },
    {
      site: "SPA pago de facturas orange",
      url: [
        {
          name: "Portal de cobros facturas",
          value: "https://pagos.orange.es/web/app/digicob-flow?execution=e2s1",
          description: "Abre el portal de cobros facturas de Orange",
        },
      ],
    },       
    {
      site: "COMPROBACION MENSAJES DE ERROR",
      url: [
        {
          name: "INT",
          value:
            "https://edicion-uat.int.si.orange.es/sites/Satellite?pagename=OR_SE/REST/MessagesController&site=EcareResidencial&message=my_grup_code-productOrdering-200",
          description: "Comprobar si mi mensaje a sido publicado en INT",
        },
        {
          name: "UAT",
          value:
            "https://edicion.int.si.orange.es/sites/Satellite?pagename=OR_SE/REST/MessagesController&site=EcareResidencial&message=my_grup_code-productOrdering-200",
          description: "Comprobar si mi mensaje a sido publicado en UAT",
        },
        {
          name: "PRO",
          value:
            "https://ecareresidencial.si.orange.es/sites/Satellite?pagename=OR_SE/REST/MessagesController&site=EcareResidencial&message=sim_module-activationAndConfiguration-162",
          description: "Comprobar si mi mensaje a sido publicado en PRO",
        },
      ],
    },
    {
      site: "JENKINS",
      url: [
        {
          name: "Nuevos componentes TC3",
          value: "http://ciserver-soe-tc.shared-nonprod.cloud.si.orange.es/",
          description:
            "Comprobar despliegues en jenkins nuevos componentes tras TC3",
        },
        {
          name: "Componentes Legacy TC3",
          value:
            "http://ciserver-soe-legtc.shared-nonprod.cloud.si.orange.es/job/componenteslegacy/",
          description:
            "Comprobar despliegues en jenkins componentes legacy, repositorios anteriores a TC3",
        },
        {
          name: "Despliegues en TC2",
          value: "https://torredecontrol.si.orange.es/jenkinsLibra/",
          description:
            "Comprobar despliegues en jenkins componentes legacy, repositorios anteriores a TC3",
        },
      ],
    },
    {
      site: "NEXUS",
      url: [
        {
          name: "Artifactory",
          value:
            "http://artifactsrepository-tc.shared-nonprod.cloud.si.orange.es/artifactory/webapp/#/home",
          description:
            "Comprobar elemento publicados en artifactory tras el despliegue en jenkins",
        },
      ],
    },
    {
      site: "DROOLS",
      url: [
        {
          name: "Drools",
          value:
            "https://publicacion.si.orange.es/sites/REST/controller/Utils/45422509Q/NIF/45422509Q/getDataDrools",
          description: "Comprobar el perfilado que se le aplica al cliente",
        },
      ],
    },
    {
      site: "SONAR",
      url: [
        {
          name: "SONAR TC3",
          value: "http://softwarequality-tc.shared-nonprod.cloud.si.orange.es/",
          description:
            "Comprobar la cobertura y calidad de los componentes desplegados",
        },
      ],
    },
    {
      site: "PIZARRAS JIRA",
      url: [
        {
          name: "Daily",
          value:
            "https://jira.si.orange.es/secure/RapidBoard.jspa?rapidView=21476",
          description: "Pizarra Daily",
        },
        {
          name: "Pizarra Individual",
          value:
            "https://jira.si.orange.es/secure/Dashboard.jspa?selectPageId=22700",
          description: "Pizarra Individual",
        },
        {
          name: "Seguimiento x Proyectos",
          value:
            "https://jira.si.orange.es/secure/RapidBoard.jspa?rapidView=13116",
          description: "Pizarra Seguimiento x Proyectos",
        },
        {
          name: "Seguimiento x Equipos",
          value:
            "https://jira.si.orange.es/secure/RapidBoard.jspa?rapidView=13336",
          description: "Pizarra Seguimiento x Equipos",
        },
        {
          name: "Seguimiento y control de horas",
          value:
            "https://jira.si.orange.es/secure/RapidBoard.jspa?rapidView=13336",
          description:
            "https://jira.si.orange.es/plugins/servlet/eazybi/accounts/55/dashboards/144-seguimiento-componentes-comunes",
        },
      ],
    },
    {
      site: "MDG",
      url: [
        {
          name: "MDG PRO - MSISDN",
          value:
            "http://areaprivada.si.orange.es/mdgRest/rest/mdg/getClientMDGRest/NumeroDocumento/12758040D/TipoAcceso/MSISDN/Marca/Orange/MSISDN/644161646",
            desplegado:"Comprobar datos devueltos por MDG recurso en producción mediante el MSISDN"
        },
        {
          name: "MDG PRO - NIF",
          value:
            "http://areaprivada.si.orange.es/mdgRest/rest/mdg/getClientMDGRest/NumeroDocumento/25702349X/TipoAcceso/NIF_CIF/Marca/Orange",
            desplegado:"Comprobar datos devueltos por MDG recurso en producción mediante el NIF"
        },
        {
          name: "MDG UAT - MSISDN",
          value:
            "https://aotlxppwsb00001:9061/ms-mdg/v1/getClientMDGRest/NumeroDocumento/sdfsdf/TipoAcceso/MSISDN/Marca/Orange/MSISDN/747801957/OnlyActive/true",
            desplegado:"Comprobar datos devueltos por MDG recurso en UAT mediante el MSISDN"
        },
        {
          name: "MDG UAT - NIF",
          value:
            "https://aotlxppwsb00001:9061/ms-mdg/v1/getClientMDGRest/NumeroDocumento/Z5159545Y/TipoAcceso/NIF_CIF/Marca/Orange/OnlyActive/true",
            desplegado:"Comprobar datos devueltos por MDG recurso en UAT mediante el NIF"
        },
        {
          name: "MDG INT - MSISDN",
          value:
            '"https://10.132.74.132:9061/ms-mdg/v1/getClientMDGRest/NumeroDocumento/sdfsdf/TipoAcceso/MSISDN/Marca/Orange/MSISDN/656004394/OnlyActive/true',
            desplegado:"Comprobar datos devueltos por MDG recurso en INT mediante el MSISDN"
        },
        {
          name: "MDG INT - NIF",
          value:
            "https://10.132.74.132:9061/ms-mdg/v1/getClientMDGRest/NumeroDocumento/Z5159545Y/TipoAcceso/NIF_CIF/Marca/Orange/OnlyActive/true",
            desplegado:"Comprobar datos devueltos por MDG recurso en INT mediante el NIF"
        },
      ],
    },
    {
      site: "CATALOGOS",
      url: [
        {
          name: "UAT",
          value:
            "https://satellite-cms-uat.int.si.orange.es/cms/miOrange/ofertacomercial.json",
            description: "Catalogo desplegado en UAT SOE",
        },
        {
          name: "PRO",
          value:
            "https://satellite-cms.si.orange.es/cms/miOrange/ofertacomercial.jsons",
            description: "Catalogo desplegado en PRO SOE",
        },
      ],
    },
    {
      site: "EVERIS",
      url: [
        {
          name: "ONE RP / Imputación TL´s",
          value:
            "https://login-xternal.everis.com/?ID=f61c39d2-13a7-4a76-ca7c-565084f855a0#/login",
          description: "Imputar TL´S y pases",
        },
        {
          name: "KNOWLER",
          value: "https://knowler.everis.com/",
          description: "Aplicación interna Everis",
        },
      ],
    },
  ];
  constructor() {}

  ngOnInit() {}

  changeVisibility(item) {
    let vm = this;
    if (!item.visibility) {
      item.visibility = true;
    } else {
      if (item.visibility == true) {
        item.visibility = false;
      } else {
        item.visibility = true;
      }
    }
  }
  closeAll() {
    let vm = this;
    this.documentation.map(function (item: any) {
      item.visibility = false;
    });
  }
}
